import {type MetaDescriptor} from '@remix-run/node';
import {META} from '#/app/root.js';

export function createMetaTags({
  description: _description,
  hideFromRobots,
  image,
  title: _title,
  url,
}: {
  title?: string;
  description?: string;
  image?: string;
  url: string;
  hideFromRobots?: boolean;
}) {
  const title = _title ?? META.title;
  const description = _description ?? META.description;

  return (
    [
      {title: title},
      {content: description, name: 'description'},
      {content: title, property: 'og:title'},
      {content: description, property: 'og:description'},
      image ? {content: image, property: 'og:image'} : undefined,
      {content: url, property: 'og:url'},
      {content: url, property: 'twitter:url'},
      {content: title, name: 'twitter:title'},
      image ? {content: description, name: 'twitter:description'} : undefined,
      {content: image, name: 'twitter:image'},
      {
        'script:ld+json': {
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          description,
          headline: title,
          image,
          mainEntityOfPage: {
            '@id': url,
            '@type': 'WebPage',
          },
          name: 'Ufleet',
          url,
        },
      },
      {href: url, rel: 'canonical', tagName: 'link'},
      hideFromRobots === true ? {content: 'noindex, nofollow', name: 'robots'} : undefined,
    ] as MetaDescriptor[]
  ).filter(Boolean);
}
